/* You can add global styles to this file, and also import other style files */

// @import '~bootstrap/scss/bootstrap';
// Replace above line with
@import 'node_modules/bootstrap/scss/bootstrap';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
    height: 100%;
    padding: 0;
    margin: 0;
}

iframe[name='JSD widget'] {
// margin-bottom: 100px;
}
